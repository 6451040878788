import React from "react";
import { graphql } from 'gatsby';

import Layout from "../../components/layout/layout"
import SEO from "../../components/layout/seo"
import HeikeKatharina from "../../components/pages/heike-kabisch-katharina-kiebacher/heike-kabisch-katharina-kiebacher"
import AccessWrapper from '../../components/pages/AccessWrapper'


const HeikeKabischPage2 = ({ data }) => {

  const story = data.storyblokEntry
  const content = JSON.parse(story.content)

  // console.log(content)
  return(
    <Layout>
      <SEO title="Home" />
        <AccessWrapper introString='Heike Kabisch & Katharina Kiebacher' content={content.artists[1]} id={1}>
          <HeikeKatharina
          video={content.heike_katharina_video}
          images={content.heike_katharina_images}
          // image={content.heike_katharina_mask}
          text={content.heike_katharina_text}
          />
        </AccessWrapper>
    </Layout>
  )
}

export default HeikeKabischPage2

export const query = graphql`
  query {
    storyblokEntry(slug: { eq: "artists" }) {
      content
    }
  }
`