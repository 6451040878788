import React, { useState } from "react"
import style from './heike-kabisch-katharina-kiebacher.module.scss';

import VideoPlayer from '../../elements/videoPlayer';
// import RandomPosImage from './randomPosImage';
// import shuffle from '../../../helpers/shuffle'
// import Image from "../../elements/image";
import RichText from "../../layout/richText";


const HeikeKabisch = ({ video, images, text }) => {

    console.log(video, images)

    const [ state, setState ] = useState({
      // images: images,
      showText: true
    })


    const handleOnClick = () => {
      setState({ ...state, showText: !state.showText })
    }

    // TODO Katharina
    // - das Video müsste in einem Loop laufen, wenn man alles durchgeschaut hat, bleibt sonst nur ein schwarzer Bildschirm stehen
    // - bei dem Video ist ja auch eigentlich Sound bei den Videosequenzen dabei, den konnte ich jetzt nicht anstellen... oder ist der ganz rausgefallen?
    // TODO
    // – Text noch integrieren



    return(
        <div className={style.heike}>
          <VideoPlayer
          video={video}
          padding={8}
          className={style.video}
          onClick={handleOnClick}
          // isMuted={true}
          />
        {state.showText && <div className={style.info} onClick={handleOnClick}>
            <div>
              <RichText text={text} />
            </div>
          </div>}
          <div className={style.switchButton} onClick={handleOnClick}>
            {state.showText ? 'Show Video' : 'Show Text'}
          </div>
        </div>
    )
}

export default HeikeKabisch

